import React, { useCallback } from "react";
import ReactDOMClient from "react-dom/client";
import App from "./App";
import ed from "./images/default.png";
import Settings from "./components/setttings";
import ReactDOM from "react-dom";
import Howler, { Howl } from "howler";

document.addEventListener("DOMContentLoaded", (event) => {
  const api = "https://europarksmc.eu/epmc-uploads/";
  const serverUrl = "wss://europarksmc.eu:2053";
  const socket = new WebSocket(serverUrl);
  const root = ReactDOMClient.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

  const urlParams = new URLSearchParams(window.location.search);
  let hasName = urlParams.has("name");
  let hasUuid = urlParams.has("uuid");
  let hasPin = urlParams.has("pin");
  let authenticated = false;
  let audio_volume = 0.1;
  let effect_volume = 0.1;
  let connected = false;
  let effects = [];
  let sounds = [];
  let currentMusic;
  let logout = false;
  let validURL = false;
  let afk = false;

  function connectToWebSocket() {
    socket.onopen = () => {
      console.log("WebSocket-Verbindung geöffnet");
      authenticated = true;
      if (hasName && hasPin && hasUuid) {
        validURL = true;

        build();
        setInterval(() => {
          if (socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify({ type: "ping" }));
          }
        }, 30000);
      }
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (
        data.event == "Client_disconnect" &&
        data.uuid == urlParams.get("uuid") &&
        data.pin == urlParams.get("pin")
      ) {
        console.log("disconnect event!");
        logout = true;
        connected = false;
        Howler.Howler.stop();
        effects = [];
        sounds = [];
        const settings = document.getElementById("settings");

        const img = document.getElementById("skin");
        img.src = ed;

        if (settings != null) ReactDOM.unmountComponentAtNode(settings);

        ReactDOM.render(
          <h1 className="welcome">
            Please use /audio ingame to get a working Audioclient!
          </h1>,
          document.getElementById("right")
        );

        socket.close(3001, "You aren't online anymore!");
      }

      if (
        data.event == "gui_close" &&
        data.uuid == urlParams.get("uuid") &&
        data.pin == urlParams.get("pin")
      ) {
        applyVolumeReduction(1.0);
      }

      if (
        data.event == "gui_open" &&
        data.uuid == urlParams.get("uuid") &&
        data.pin == urlParams.get("pin")
      ) {
        applyVolumeReduction(0.2);
      }

      if (
        data.event == "afk_status" &&
        data.uuid == urlParams.get("uuid") &&
        data.pin == urlParams.get("pin")
      ) {
        handleAFK(data.state);
      }

      if (
        data.event == "playeffect" &&
        data.uuid == urlParams.get("uuid") &&
        data.pin == urlParams.get("pin")
      ) {
        playEffect(data.name, false, false);
      }

      if (
        data.event == "stopsoundeffect" &&
        data.uuid == urlParams.get("uuid") &&
        data.pin == urlParams.get("pin")
      ) {
        stopEffect(data.name);
      }

      if (
        data.event == "playeffectscene" &&
        data.uuid == urlParams.get("uuid") &&
        data.pin == urlParams.get("pin")
      ) {
        console.log("DEBUG -> EffectScene started!");
        playEffect(data.name, true, true);
      }

      if (
        data.event == "stopeffectscene" &&
        data.uuid == urlParams.get("uuid") &&
        data.pin == urlParams.get("pin")
      ) {
        stopEffect(data.name);
      }

      if (
        data.event == "playmusic" &&
        data.uuid == urlParams.get("uuid") &&
        data.pin == urlParams.get("pin")
      ) {
        playMusic(data.name, data.pos, data.loop);
      }

      if (
        data.event == "stopmusic" &&
        data.uuid == urlParams.get("uuid") &&
        data.pin == urlParams.get("pin")
      ) {
        stopMusic();
        console.log("Music has stopped!");
      }

      if (data.event == "PING") {
        sendMessage(JSON.stringify({ event: "PONG" }));
      }
    };

    socket.onclose = (event) => {
      if (event.wasClean) {
        console.log(
          `Closed cleanly, code=${event.code}, reason=${event.reason}`
        );
      } else {
        console.log("Connection died");
      }
      logout = true;
      connected = false;
      Howler.Howler.stop();
      effects = [];
      sounds = [];
      const settings = document.getElementById("settings");

      const img = document.getElementById("skin");
      img.src = ed;

      if (settings != null) ReactDOM.unmountComponentAtNode(settings);

      ReactDOM.render(
        <h1 className="welcome">
          Please use /audio ingame to get a working Audioclient!
        </h1>,
        document.getElementById("right")
      );
    };
  }

  function sendMessage(message) {
    if (socket.readyState === WebSocket.OPEN) {
      socket.send(message);
    }
  }

  connectToWebSocket();

  function build() {
    window.onclick = () => {
      if (!authenticated) {
        return;
      }
      if (!logout && !connected) {
        playEffect("start", false, false);

        sendMessage(
          JSON.stringify({
            event: "connection",
            uuid: urlParams.get("uuid"),
            pin: urlParams.get("pin"),
          })
        );

        sendMessage(
          JSON.stringify({
            event: "musicEnded",
            uuid: urlParams.get("uuid"),
            pin: urlParams.get("pin"),
          })
        );

        const right = document.getElementById("right");

        connected = true;

        ReactDOM.render(<Settings name={urlParams.get("name")} />, right);

        const audio_volume_input = document.getElementById("volume_audios");
        const effect_volume_input = document.getElementById("volume_effects");
        const music_label = document.getElementById("musiclabel");
        const effect_label = document.getElementById("effectlabel");

        audio_volume_input.addEventListener("input", () => {
          audio_volume = (audio_volume_input.value / 1000) * 2;
          music_label.innerText = "Music | " + audio_volume_input.value + "%";
          sounds.map((sound) => {
            sound.volume = audio_volume;
            sound.sound.volume(audio_volume);
          });
        });

        effect_volume_input.addEventListener("input", () => {
          effect_volume = (effect_volume_input.value / 1000) * 2;
          effect_label.innerText =
            "Effects | " + effect_volume_input.value + "%";
          effects.map((effect) => {
            effect.volume = effect_volume;
            effect.effect.volume(effect_volume);
          });
        });

        const img = document.getElementById("skin");
        img.src =
          "https://mc-heads.net/avatar/" +
          urlParams.get("uuid").replace("-", "");
      }
    };
  }

  window.addEventListener("beforeunload", (event) => {
    sendMessage(
      JSON.stringify({
        event: "logout",
        uuid: urlParams.get("uuid"),
        pin: urlParams.get("pin"),
      })
    );
  });

  function stopEffect(name) {
    effects.forEach((e) => {
      if (e.name == name) {
        e.fadeOut();
      }
    });
  }

  function playEffect(name, looped, fade) {
    new EffectObject(name, looped, fade);
  }

  function playMusic(name, pos, looped) {
    if (hasOnboard() == false) {
      console.log("Should play now " + name);
      stopMusic();
      new SoundObject(name, pos, looped, !looped);
    }
  }

  function stopMusic() {
    sounds.forEach((sound) => {
      sound.fadeOut(audio_volume);
    });
  }

  function handleAFK(state) {
    if (state == true) {
      afk = true;
      applyUnderwaterEffect();
    } else {
      afk = false;
      removeUnderwaterEffect();
    }
  }

  function applyVolumeReduction(volumeLevel) {
    sounds.map((sound) => {
      sound.volume = audio_volume;
      sound.sound.volume(audio_volume * volumeLevel);
    });
  }

  function removeUnderwaterEffect() {
    // Audio-Context von Howler.js erhalten
    var ctx = Howler.ctx;

    // Alle aktiven Sounds durchgehen
    Howler._howls.forEach(function (howl) {
      howl._sounds.forEach(function (sound) {
        var source = sound._node;
        if (source) {
          // Verbindung trennen und direkt mit dem Ziel verbinden
          source.disconnect();
          source.connect(ctx.destination);
        }
      });
    });
  }

  function applyUnderwaterEffect() {
    // Audio-Context von Howler.js erhalten
    var ctx = Howler.ctx;

    // Alle aktiven Sounds durchgehen
    Howler._howls.forEach(function (howl) {
      howl._sounds.forEach(function (sound) {
        var source = sound._node;
        if (source) {
          // Lowpass-Filter erstellen
          var lowpassFilter = ctx.createBiquadFilter();
          lowpassFilter.type = "lowpass";
          lowpassFilter.frequency.setValueAtTime(1000, ctx.currentTime); // Frequenz auf 1000 Hz setzen
          lowpassFilter.Q.setValueAtTime(1, ctx.currentTime); // Q-Wert auf 1 setzen

          // Quelle mit dem Filter verbinden
          source.disconnect();
          source.connect(lowpassFilter);
          lowpassFilter.connect(ctx.destination);
        }
      });
    });
  }

  function hasOnboard() {
    if (sounds.length == 0) {
      return false;
    }

    let bool = false;
    sounds.map((sound) => {
      if (sound.onboard) {
        bool = true;
      }
    });
    return bool;
  }

  class EffectObject {
    constructor(name, loop, fade) {
      this.id = Date.now();
      this.name = name;
      this.loop = loop;
      this.infader = null;
      if (fade) {
        this.volume = 0;
      } else {
        this.volume = effect_volume;
      }
      this.effect = new Howl({
        src: ["../sounds/" + name + ".mp3"],
        volume: this.volume,
        autoplay: false,
        loop: loop,
        html5: true,
        onloaderror: function (id, err) {
          console.log("An error occurred => " + err);
        },
      });

      this.effect.on("load", () => {
        this.effect.play();
        if (fade) {
          this.fadeIn(effect_volume);
        }
      });

      if (!loop) {
        this.effect.once("end", function () {
          effects = effects.filter((effect) => effect.id != this.id);
        });
      }
      effects.push(this);
    }

    fadeOut(effect_volume) {
      effects = effects.filter((effect) => effect.id != this.id);
      if (this.infader != null) {
        this.volume = 0;
        this.effect.stop();
        this.effect.unload();
        return;
      }

      let index = 0;
      let increment = effect_volume / 10;
      let outfader = setInterval(() => {
        this.volume = this.volume - increment;
        this.effect.volume(this.volume);
        if (index >= 10) {
          clearInterval(outfader);
          this.effect.stop();
          this.effect.unload();
          this.volume = 0;
        }
        index++;
      }, 200);
    }

    fadeIn(effect_volume) {
      let index = 0;
      let increment = effect_volume / 10;
      this.infader = setInterval(() => {
        this.volume = this.volume + increment;
        this.effect.volume(this.volume);

        if (index >= 10) {
          clearInterval(this.infader);
          this.infader = null;
        }
        index++;
      }, 200);
    }
  }

  class SoundObject {
    constructor(name, pos, loop, onboard) {
      this.id = Date.now();
      this.name = name;
      this.pos = pos;
      this.loop = loop;
      this.onboard = onboard;
      this.infader = null;
      this.volume = 0;
      this.sound = new Howl({
        src: ["../sounds/" + name + ".mp3"],
        volume: this.volume,
        autoplay: false,
        loop: loop,
        html5: true,
        onloaderror: function (id, err) {
          console.log("An error occurred => " + err);
        },
      });

      this.sound.on("load", () => {
        this.sound.seek(pos);
        this.sound.play();
        this.fadeIn(audio_volume);
      });

      if (!loop) {
        this.sound.once("end", function () {
          sounds = sounds.filter((sound) => sound.id != this.id);
          if (socket.readyState === WebSocket.OPEN) {
            console.log("Sound has ended!\n" + sounds);
            socket.send(
              JSON.stringify({
                event: "musicEnded",
                uuid: urlParams.get("uuid"),
                pin: urlParams.get("pin"),
              })
            );
          }
        });
      }
      sounds.push(this);
    }

    fadeOut(audio_volume) {
      sounds = sounds.filter((sound) => sound.id != this.id);
      if (this.infader != null) {
        this.volume = 0;
        this.sound.stop();
        this.sound.unload();
        return;
      }

      let index = 0;
      let increment = audio_volume / 10;
      let outfader = setInterval(() => {
        this.volume = this.volume - increment;
        this.sound.volume(this.volume);
        if (index >= 10) {
          clearInterval(outfader);
          this.sound.stop();
          this.sound.unload();
          this.volume = 0;
        }
        index++;
      }, 200);
    }

    fadeIn(audio_volume) {
      let index = 0;
      let increment = audio_volume / 10;
      this.infader = setInterval(() => {
        this.volume = this.volume + increment;
        this.sound.volume(this.volume);

        if (index >= 10) {
          clearInterval(this.infader);
          this.infader = null;
        }
        index++;
      }, 200);
    }
  }
});
